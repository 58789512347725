.about-tabs-container{
    margin-top:2em;
    margin-bottom: 2em;
    padding-top: 2em;
    padding-bottom: 2em;
}
.about-tabs-content-col{
   float: left;
   text-align: left;
}

.tabs-heading-about{
    color: #e21f36 !important;
}
.tabs-heading-description{
    font-size: 1.5em;
    color: #000219 !important;  
}

.about-tabs-content-col .nav-link{
    color: #000219 !important ;
    text-decoration: underline !important;
    text-underline-offset: 30% !important;
    text-decoration-thickness: 25% !important;
    border: 2px solid transparent !important;
    background-color: transparent !important;
}
.nav-link.active{
    color: #e21f36 !important ;
}
.tab-content-paragraph{
    color: #4b5280 !important;
    font-size: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
}
.approuch-card{
    border-left: 2px solid #e21f36;
    margin: 1% 0% 2% 2% ;
    padding: 1em 0.5em .5em 0.5em ;
    background-color: transparent;
    transition: all .4s ease-in-out;
    background-image: linear-gradient(#000219,#000219) ;
    background-position-x: -60em ;
    background-size: contain;
    background-repeat: no-repeat;
}

.approuch-card-title{
    transition: all .5s ease-out;
    font-size: 1.2em;
}
.approuch-card-paragraph{
    color: #4b5280 !important;
    transition: all .5s ease-out;
    font-size: .9em;
}
.approuch-card:hover{
    background-position-x: 0em ;
}
.approuch-card:hover p{
    color: #fff !important;
}
.approuch-card:hover h3{
    color: #fff !important;
}
