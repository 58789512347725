@keyframes img-moving {
    0%{
        
    }
    50%{
        background-size: 100% 130%;
    }
    100%{
        background-size: 100% 100%;
    }
}
.olympus-home-banner-container{
    margin-bottom: 2em;
    margin-top: 5.5em !important;
}
.olympus-home-banner-row{
    background-image: url('https://iaterial.sirv.com/Olympus/cybersecurity-awareness-month-2020%20(1).png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 20em;
    animation-name: img-moving;
    animation-duration: 45s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}
.olympus-home-banner-content-col{
    padding-top: 4em;
    padding-bottom: 6em;
}
.olympus-home-banner-heading{
    color: #fff;
    margin-bottom: 5%;
}
.olympus-home-banner-btn{
    background-color: #960605;
    padding: .5em 1em .5em 1em;
    color: #fff;
    font-weight: bold;
    border: 0px ;
    transition: all .1s ease-in-out;
    transform: translateY(0);

}
.olympus-home-banner-btn:hover{
    background-color: #a52a2a;
    border: 2px solid #fff;
    transform: translateY(-10%);
}
@media screen and (max-width:350px){
    .olympus-home-banner-row{
        height: 22em;
    }
    .olympus-home-banner-btn{
        padding: 0.2em 0.2em 0.2em 0.2em
    }
    .olympus-home-banner-content-col{
        padding-top: 1em;
        padding-bottom: 1em;
    }
}
