.page-banner-container{
    margin-top: 5.5em;
}
.page-banner-row{
    background-color: #000;
    padding-top: 2em;
    padding-bottom: 2em;
}
.page-banner-col-title{
    text-align: center !important;
    align-items: center;
}
.page-banner-heading{
    color: #fff;
    font-size: 2.5em;
    font-weight: bold;
}
.page-banner-nav{
   text-align: center;
    margin-left: 44%;
}
.page-banner-nav h6{
    font-weight: lighter;
}
.page-banner-home-content{
    display: flex;
    text-align: center;
    
}
.page-banner-home-heading{
    font-weight: bold;
    color: #fff;
    transition: all .5s ease;
}
.page-banner-home-content:hover .home-icon{
    color: #e21f36 !important;
}
.page-banner-home-content:hover .page-banner-home-heading{
    color: #e21f36 !important;
}
.page-banner-icon-span{
    width: .5em;
}
.page-banner-home-span{
    width: 1em;
}
.page-banner-about-span{
    color: transparent;
}
.home-icon{
    color: #fff;
    transition: all .5s ease;
}
.page-banner-icon-span{
    color: transparent;
}
.page-banner-nav-item:hover{
    color: #e21f36 ;
}
.page-banner-about-heading{
    color: #e21f36;
}
@media screen and (max-width:768px){
    .page-banner-nav{
        margin-left: 40%;
    }
}
@media screen and (max-width:576px){
    .page-banner-nav{
        margin-left: 35%;
    }
}
