.olympus-protection-container{
    margin-bottom: 0em;
    background-image: linear-gradient(#000,#000,#000, #000,#000,#000,#550202);
    padding-top: 2em;
    padding-bottom: 2em;

}
.protection-heading{
    color: #fff;
    text-align: center;
    margin-bottom: 1em;

}
.protection-card-col{
    margin-bottom: 1em;
    transition: all .3s ease-in-out;
}
.protection-title-card-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    padding-top: .5em;
    color: #fff;
    font-weight: bold;
}
.challenges-card-title{
    padding-left: 2em;
}
.challenges-card-description{
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
}
.protection-card-col:hover{
    background-image: linear-gradient(to top, #000, #550202);
    color: #fff;
    transform: translateY(-5%);
}
.protection-card-col:hover .bi{
    color: #fff;
    transform: rotate(0deg) scale(1.8);
}
@media screen and (min-width: 1100px){
    .challenges-card-description{
        color: transparent;
        transition: all .2s ease-in-out;
    }
    .protection-card-col:hover .challenges-card-description{
        color: #fff;
    }
}
@media screen and (max-width: 992px) {
    .protection-card-col{
        color: #fff;
    }
    .protection-card-col:hover{
        background-image: linear-gradient(transparent,transparent)!important;
    }
    .protection-card-col .bi{
        color: #fff;
    }
    .olympus-protection-container{
        background-image: linear-gradient(to bottom, #550202,#000,#000,#550202);
    }


}
@media screen and (max-width: 575px) {
    .protection-card-col{
        margin-bottom:0em ;
        color: #fff;
    }
    .protection-card-col:hover{
        background-image: linear-gradient(transparent,transparent)!important;
    }
    .protection-card-col .bi{
        color: #fff;
    }
    .olympus-protection-container{
        background-image: linear-gradient(to bottom, #550202,#000,#000,#550202);
    }


}
