.footer-container{
    background-image: linear-gradient(to top, #000, #000,#000,#000, #960605);
    margin-top: 0em !important;
}
.footer-info-row{
    padding-top: 2em;
    padding-bottom: 1em;
    border-bottom: 1px solid ;
}
.footer-logo-container{
    background-image: url('https://iaterial.sirv.com/Olympus/Olympus-Shield-logo-white.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 4.5em;
    width: 4.5em;
    margin-bottom: 1em;
}
.footer-social-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 125%;
}
.footer-social-icon{
    color: #fff;
    transition: all .3s ease-in-out;
}
.footer-social-icon:hover{
    color: #960605;
    transform: translateY(-5%);
}
.address-heading{
    color: #fff;
    font-weight: bold;
    margin-left: 2em;
    font-size: 1.2em;
    padding-bottom: 3%;
    border-bottom: .00000000000001em solid #fff;
    border-width: thin;
}
 .address-list li{
    list-style: none;
    padding-top: 1em;
    display: flex;
    
}
.address-list-icon{
    color: #e21f36;
    transition: all .3s ease-in-out;
    font-size: 2em !important;

}
.address-list-item{
    color: #fff;
    text-align: left;
    font-size: .9em;    
}
.address-list-link{
    display: flex;
    justify-content: space-between;
    color: #fff;
    text-decoration: none;
    transition: all .3s ease-in-out;
}
.address-list-link:hover{
    color: #e21f36;
    
}
.address-list-link:hover .address-list-icon{
    color: #fff;
}

.footer-policy-row{
    padding-top: .8em;
    padding-bottom: .5em;
    border-top: 1px solid ;
}
.designer-sign{
    display: flex;
    flex-direction: row;
}
.designer-icon{
    color: #fff;
}
.designer-sign-heading{
    color: #fff;
    font-size: .8em;
    margin-left: 3%;
    font-weight: lighter;
}
.designer-sign-span{
    color: #e21f36;
    font-weight: bold;
}
.privacy-policy{
    color: #fff;
    float: right;
    text-align: right;
    transition: all .5s ease;
}
.privacy-policy:hover{
    color: #e21f36;   
}
.terms-conditions{
    color: #fff;
    transition: all .5s ease;
    float: left;
    text-align: left;
}
.terms-conditions:hover{
    color: #e21f36;   
}
.designer-link{
    text-decoration: none;
}
.footer-social-icon-empty{
    display: none;
}
@media screen and (min-width: 1200px){
    .address-heading{
        width: 15em;
    }
}
@media screen and (max-width:1400px){
    .footer-social-container{
        width: 140%;
    }
}
@media screen and (max-width:1199px){
    .footer-social-container{
        width:100%;
    }
}

@media screen and (max-width:575px) {
    .address-heading{
        margin-left: 0em;
        padding-left: 2em;
    }
    .footer-social-container{
        margin-bottom: 1em;
        width: 150%;
    }
}
@media screen and (max-width:340px) {
    .footer-social-container{
        width: 200%;
    }
}
@media screen and (max-width:270px) {
    .footer-social-container{
        width: 250%;
    }
}
@media screen and (max-width:220px) {
    .footer-social-container{
        width: 300%;
    }
}