.trust-container{
    margin-bottom: 2em;
    margin-top: 0em !important;
    background-image: linear-gradient(to top ,#000,#000,#000, #000,#000,#000,#550202);
    padding-top: 2.5em;

}

.trust-card-col{
    border: 1px solid;
    margin-bottom: 1.5em;
    transition: all .3s ease-in-out;
}
.trust-title{
    color: #fff;
    margin-bottom: 1em;
}
.why-us-card-container{
    display: flex;
    flex-direction: row;
    padding-top: 1em;
    padding-left: 1em;
}
.why-us-card-text{
    color: #fff;
    padding-left: 2em;
    width: 100%;
    padding-bottom: 1em;

}
.why-us-card-title{
    color: #fff;
    font-size: 1.2em;
}
.trust-card-col:hover{
    transform: translateY(-5%);
    border-color: red;
}
.trust-card-col{
    display: flex ;
    flex-direction: row;
}
@media screen and (max-width:1199px) {
    .trust-row{
        background-image: none;
    }
}

