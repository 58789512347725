.customer-review-row{
    background-color: #000219 ;
    padding-top: 2em ;
    padding-bottom: 2em ;
}
.customer-review-col-content{
    text-align: center;
}
.customer-review-heading{
    color: #fff;
    padding-bottom: 3%;
}
.quotes-icon{
    font-size: 4em;
    color: #e21f36;
}
.customer-review-description{
    font-size: 1em;
    color: #fff
}
.star-review-icon{
    color: #e21f36;
    font-size: 1.4em;
    margin-bottom: 2%;
}
.customer-name-heading{
    font-size: 1.2em;
    color: #fff;
    font-weight: bold
}
.customer-position-heading{
    color: #fff;
    margin-bottom: 5%;
}
.customer-review-btn{
    margin-top: 3%;
    z-index: 1;
    font-weight: 600;
    font-size: 1em;
    color: #fff;
    border: 0px;
    padding: 1em 2em 1em;
    line-height: 1;
    transition: all .5s ease;
    text-align: center;
    background-color: #e21f36;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    width: 10em;
}
.customer-review-btn:hover{
    background-color:  #f89757;
    
}
.customer-review-btn::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0;
    height: 100%;
    background: transparent;
    border: 2px solid #fff;
}

.customer-review-btn::before {
    animation: animate  3.000111s linear  infinite;
    animation-fill-mode: both;
    animation-play-state: running;
}

@keyframes animate {
    0% {
        width: 0;
        height: 0;
        border-top-color: #fff;
        border-right-color: #fff;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }

    50% {
        width: 100%;
        height: 0;
        border-top-color: #fff;
        border-right-color: #fff;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }

    100% {
        width: 100%;
        height: 100%;
        border-top-color: transparent;
        border-right-color: #fff;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }


}

.customer-review-btn::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0px;
    right: 0px;
    width: 0;
    height: 100%;
    background: transparent;
    border: 2px solid;
}

.customer-review-btn::after {
    animation: animates  3s linear  infinite;
    animation-play-state: running;
}
@keyframes animates {
    0% {
        width: 0;
        height: 0;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: #fff;
        border-left-color: transparent;
    }

    50% {
        width: 100%;
        height: 0%;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: #fff;
        border-left-color: #fff;
    }

    100% {
        width: 100%;
        height: 100%;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: #fff;
    }


}