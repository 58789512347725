.customers-container{
    margin-bottom: 1em;
}
.customers-carousel{
    height: 100%;
}
.customer-card{
    height: 100%;
}
.customer-card img{
    padding-left: 35%;
    padding-right: 35%;
    height: 10em;
    margin-bottom: 10em;
}
.customer-link-heading{
    text-decoration: none;
    color: #000;
}
.customer-link-heading:hover{
    color: #960605;
}

#iel-img{
padding-left: 45%;
padding-right: 45%;
}
#leb-file-img{
    padding-left: 45%;
    padding-right: 45%;

}
#leb-on-img{
    padding-left: 45%;
    padding-right: 45%;
}
#mic-img{
    padding-left: 35%;
    padding-right: 35%;
    padding-top: 5%;
}
#gom-img{
    padding-left: 42%;
    padding-right: 42%;
    padding-top: 0%;
}
@media screen and (max-width:1200px){
    #iel-img{
        padding-left: 40%;
        padding-right: 40%;
    }
    #leb-file-img{
        padding-left: 40%;
        padding-right: 40%;
    
    }
    #leb-on-img{
        padding-left: 40%;
        padding-right: 40%;
    }
    #mic-img{
        padding-left: 35%;
        padding-right: 35%;
        padding-top: 8%;
    }
    #vdl-img{
        padding-left: 30%;
        padding-right: 30%;
        padding-top: 5%;
    }
    #berscia-img{
        padding-top: 5%;
    }
}
@media screen and (max-width:992px){
    #dia-on-img{
        padding-top: 5%;
    }
    #tek-img{
        padding-top: 5%;
    }
    #gom-img{
        padding-left: 40%;
        padding-right: 40%;
        padding-top: 0%;
    }
}
@media screen and (max-width:768px){
    #berscia-img{
        padding-top: 10%;
    }
    #dia-on-img{
        padding-top: 10%;
    }
    #mic-img{

        padding-top: 15%;
    }
    #tek-img{
        padding-top: 10%;
    }
    #vdl-img{
        padding-top: 10%;
    }
    #gom-img{
        padding-left: 38%;
        padding-right: 38%;
        padding-top: 0%;
    }
}
@media screen and (max-width:576px){
    .customer-card img{
        height: 8em;
        padding-top: 15% !important;
    }
    #mic-img{
        padding-top: 20% !important;
    }
    #iel-img{
        padding-top: 10% !important;
    }
    #leb-file-img{
        padding-top: 10% !important;
    }
    #gom-img{
       
        padding-left: 38%;
        padding-right: 38%;
        padding-top: 5%;
    }
}
@media screen and (max-width:441px){
    .customer-card img{
        height: 8em;
        padding-top: 20% !important;
    }
    #mic-img{
        padding-top: 25% !important;
    }
    #iel-img{
        padding-top: 10% !important;
    }
    #leb-file-img{
        padding-top: 10% !important;
    }
}
@media screen and (max-width:380px){
    .customer-card img{
        height: 8em;
        padding-top: 25% !important;
    }
    #mic-img{
        padding-top: 35% !important;
    }
    #iel-img{
        padding-top: 15% !important;
    }
    #leb-file-img{
        padding-top: 10% !important;
    }
    #gom-img{
       
        padding-left: 35%;
        padding-right: 35%;
        padding-top: 5%;
    }
}

@media screen and (max-width:360px){
    .customer-card img{
        visibility: hidden !important;
        margin-bottom: 0em !important;
    }
    .customers-name{
        font-size: 1em;
    }
    .customers-container{
        margin-top: -2em;
    }
}
