.plans-container{
    margin-top: 1em;
    margin-bottom: 1em;
}
.plan-heading{
    text-align: center;
    font-weight: bold;
    margin-bottom: 1em;
}
.plans-row{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
}
.basic-plan-col{
    padding: 1em 5em 1em 1em;
}
.advanced-plan-col{
    padding: 1em 5em 1em 1em;
}
.special-plan-col{
    padding: 1em 5em 1em 1em;
}
.basic-sub-container{
    padding: 1em 1em 1em 1em;
    border: 1px solid #000;
    height: 100%;
    transition: all .3s ease-in-out;
}
.advanced-sub-container{
    padding: 1em 1em 1em 1em;
    border: 1px solid #000;
    height: 100%;
    transform: translateY(-5%);
    background-color: #000;
    color: #fff;
    border-color: #960905 !important;
}
.special-sub-container{
    padding: 1em 1em 1em 1em;
    border: 1px solid #000;
    height: 100%;
    transition: all .3s ease-in-out;
}
.plan-title{
    font-size: larger;
    font-weight: bold ;
    margin-bottom: 1em ;
}
.plan-cost{
    font-weight: bold;
    color: #960905;
    margin-bottom: 1em;
}
.basic-plan-col:hover div{
    transform: translateY(-5%);
    background-color: #000;
    color: #fff;
    border-color: #960905 !important;
}
.special-plan-col:hover div{
    transform: translateY(-5%);
    background-color: #000;
    color: #fff;
    border-color: #960905 !important;
}
@media screen and (max-width: 991px){
    .basic-plan-col{
        padding: 1em 0em 1em 0em;
    }
    .advanced-plan-col{
        padding: 1em 0em 1em 0em;
    }
    .special-plan-col{
        padding: 1em 0em 1em 0em;
    }
}